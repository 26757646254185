<template>
  <v-container bg fill-height grid-list-md text-xs-center>
    <v-layout row wrap align-center>
      <v-row justify="center">
        <v-btn color="teal white--text" depressed large @click="onLogin">
          Sign in with Abeja Platform
        </v-btn>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import { api } from '../utils'

export default {
  methods: {
    onLogin() {
      window.location.href = `${api.baseURL()}/oauth/abeja_platform/login`
    },
  },
}
</script>
